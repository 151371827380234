import React, { useState, useRef } from 'react';
import {
    Grid,
    CircularProgress,
    Box,
    Breadcrumbs,
    Link,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    useMediaQuery
} from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, Tooltip, Legend, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import CustomDialogBox from 'ui-component/DialogBox/CustomDialogBox';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

// Register plugins
ChartJS.register(Tooltip, Legend, ArcElement, ChartDataLabels);

const BreadcrumbChart = ({ initialData, initialLabel = 'Home' }) => {
    const generateChartData = (data) => {
        const labels = data.map((item) => item.category);
        const series = data.map((item) => item.count);
        return {
            labels,
            datasets: [
                {
                    data: series,
                    backgroundColor: generateColors(series.length)
                }
            ]
        };
    };

    const generateColors = (num) => {
        const colors = [];
        for (let i = 0; i < num; i++) {
            colors.push(`hsl(${(i * 360) / num}, 70%, 50%)`);
        }
        return colors;
    };

    const chartRef = useRef(null);
    const [breadcrumbs, setBreadcrumbs] = useState([{ label: initialLabel, data: initialData, chartData: generateChartData(initialData) }]);
    const [currentData, setCurrentData] = useState(breadcrumbs[0].chartData);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const getCategoryData = (categoryName, data) => {
        for (let category of data) {
            if (category.category === categoryName) {
                return category.subcategories;
            } else if (category.subcategories && category.subcategories.length > 0) {
                const result = getCategoryData(categoryName, category.subcategories);
                if (result) return result;
            }
        }
        return null;
    };

    const handleChartClick = (event, elements) => {
        if (!elements.length) return;
        const elementIndex = elements[0].index;
        const selectedLabel = currentData.labels[elementIndex];
        const selectedData = getCategoryData(selectedLabel, breadcrumbs[breadcrumbs.length - 1].data);

        if (selectedData && selectedData.length > 0) {
            const newBreadcrumb = {
                label: selectedLabel,
                data: selectedData,
                chartData: generateChartData(selectedData)
            };
            setBreadcrumbs([...breadcrumbs, newBreadcrumb]);
            setCurrentData(newBreadcrumb.chartData);
        }
    };

    const handleBreadcrumbClick = (index) => {
        const newBreadcrumbs = breadcrumbs.slice(0, index + 1);
        setBreadcrumbs(newBreadcrumbs);
        setCurrentData(newBreadcrumbs[index].chartData);
    };

    // Highlight pie section when color is clicked
    const handleColorClick = (index) => {
        const chart = chartRef.current;
        if (chart) {
            // Set the active element
            chart.setActiveElements([
                {
                    datasetIndex: 0, // Assuming you have only one dataset
                    index: index // The index of the clicked list item
                }
            ]);
            chart.tooltip.setActiveElements(
                [
                    {
                        datasetIndex: 0, // Same dataset index
                        index: index // The index of the clicked list item
                    }
                ],
                { x: 0, y: 0 }
            ); // Position of the tooltip, adjust if needed
            chart.update(); // Update the chart to reflect changes
        }
    };

    const handleClose = () => {
        setOpen(false);
    };
    const theme = useTheme();
    const ismdDown = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <Box sx={{ p: 4 }}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                {breadcrumbs.map((crumb, index) =>
                    index < breadcrumbs.length - 1 ? (
                        <Link
                            key={index}
                            underline="hover"
                            color="inherit"
                            onClick={() => handleBreadcrumbClick(index)}
                            sx={{ cursor: 'pointer' }}
                        >
                            {crumb.label}
                        </Link>
                    ) : (
                        <Typography key={index} color="gray">
                            {crumb.label}
                        </Typography>
                    )
                )}
            </Breadcrumbs>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div
                        style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', width: '100%', alignItems: 'center' }}
                    >
                        {/* List of labels with colors */}
                        <List>
                            {currentData.labels.map((label, index) => (
                                <ListItem
                                    key={index}
                                    onClick={() => handleColorClick(index)} // Add click handler
                                    sx={{ cursor: 'pointer' }} // Make it look clickable
                                >
                                    {/* Color Indicator */}
                                    <ListItemIcon>
                                        <Box
                                            sx={{
                                                width: 16,
                                                height: 16,
                                                backgroundColor: currentData.datasets[0].backgroundColor[index],
                                                borderRadius: '50%'
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText style={{ color: 'gray' }} primary={label} />
                                </ListItem>
                            ))}
                        </List>
                        <div
                            style={{
                                // backgroundColor: 'red',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '250px'
                            }}
                        >
                            {/* fhjd */}
                            {/* <Grid item xs={12} md={8}> */}
                            <Pie
                                ref={chartRef}
                                data={currentData}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    onClick: handleChartClick,
                                    plugins: {
                                        legend: { display: false },
                                        tooltip: { enabled: true },
                                        datalabels: {
                                            color: 'white',
                                            formatter: (value, context) => {
                                                return value;
                                            },
                                            font: {
                                                weight: 'bold',
                                                size: 14
                                            }
                                        }
                                    }
                                }}
                                style={{ height: ismdDown ? 200 : 350, width: 'auto' }}
                            />
                            {/* </Grid> */}
                        </div>
                    </div>
                </div>
            )}

            {/* Custom Dialog Box */}
        </Box>
    );
};

BreadcrumbChart.propTypes = {
    initialData: PropTypes.arrayOf(
        PropTypes.shape({
            category: PropTypes.string.isRequired,
            count: PropTypes.number.isRequired,
            subcategories: PropTypes.array
        })
    ).isRequired,
    initialLabel: PropTypes.string
};

export default BreadcrumbChart;
